//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddConnection from "@/components/modals/ModalAddConnection";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    img: {
      type: String
    }
  },
  data() {
    return {
      loading: this.$store.state.connections.loading
    };
  },
  methods: {
    onCreateConnection() {
      this.$access.hasOrThrow("connections");
      this.$refs.modalAddList.open();
    },
    onAdded() {
      this.$router.push({
        name: "Connections"
      });
    }
  },
  components: {
    ModalAddConnection
  }
};