//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getMarketplacesListArray } from "@/utils/enums";
import uiForm from "@/components/Form/Form";
import Tooltip from "@/components/Tooltip.vue";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const select_marketplace_items = [...getMarketplacesListArray()];
    const initial_new_connection = {
      name: "",
      datasource: {
        id: 0,
        title: "选择一个市场"
      },
      client_id: "",
      apikey: ""
    };
    return {
      initial_new_connection,
      new_connection: {
        ...initial_new_connection
      },
      mp: null,
      select_marketplace_items,
      loading: false,
      mayError: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      mp = null
    } = {}) {
      this.new_connection = {
        ...this.initial_new_connection
      };
      this.mp = mp;
      this.mayError = false;
      if (mp) {
        this.new_connection.datasource = this.select_marketplace_items.find(i => i.id === mp);
      }
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _this$new_connection$, _this$new_connection$2;
        if (((_this$new_connection$ = this.new_connection.datasource) === null || _this$new_connection$ === void 0 ? void 0 : _this$new_connection$.id) === "wb") {
          this.new_connection.client_id = "";
        }
        const result = await this.$store.dispatch("connections/createToken", {
          datasource: (_this$new_connection$2 = this.new_connection.datasource) === null || _this$new_connection$2 === void 0 ? void 0 : _this$new_connection$2.id,
          apikey: this.new_connection.apikey,
          name: this.new_connection.name,
          client_id: this.new_connection.client_id
        });
        this.loading = false;
        close();
        this.$emit("complete", result);
      } catch (e) {
        var _e$response;
        this.loading = false;
        if ((e === null || e === void 0 || (_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 507) {
          close();
          this.$modal.open(() => import("@/components/modals/access/ModalTokensLimitReached"));
          return;
        }
      }
    },
    onBlurApiKey() {
      var _this$new_connection$3;
      this.mayError = ((_this$new_connection$3 = this.new_connection.apikey) === null || _this$new_connection$3 === void 0 ? void 0 : _this$new_connection$3.length) > 0;
    }
  },
  computed: {
    tokenPlaceholder() {
      if (this.new_connection.datasource.id === "ozon") {
        return "示例： e548756d-89f3-46gh-9f13-d602c6c0a66d";
      }
      if (this.new_connection.datasource.id === "wb") {
        return "示例： Zjk1MmMzZGEtMWEyMi99YjRmLКkwYjgtZDc0MTRiMzI5ZmEw";
      }
      return "API Token";
    },
    tokenLength() {
      if (this.new_connection.datasource.id === "ozon") {
        return "36";
      }
      if (this.new_connection.datasource.id === "wb") {
        return "140";
      }
      return "140/36";
    },
    validApiKey() {
      if (this.new_connection.datasource.id === "ozon") {
        var _this$new_connection$4;
        return ((_this$new_connection$4 = this.new_connection.apikey) === null || _this$new_connection$4 === void 0 ? void 0 : _this$new_connection$4.length) === 36;
      }
      if (this.new_connection.datasource.id === "wb") {
        var _this$new_connection$5;
        return ((_this$new_connection$5 = this.new_connection.apikey) === null || _this$new_connection$5 === void 0 ? void 0 : _this$new_connection$5.length) > 140;
      }
      return false;
    },
    valid() {
      if (this.new_connection.datasource.id === "ozon") {
        var _this$new_connection$6, _this$new_connection$7;
        return ((_this$new_connection$6 = this.new_connection.name) === null || _this$new_connection$6 === void 0 ? void 0 : _this$new_connection$6.length) && ((_this$new_connection$7 = this.new_connection.datasource) === null || _this$new_connection$7 === void 0 ? void 0 : _this$new_connection$7.id) && this.new_connection.client_id.length && this.validApiKey;
      }
      if (this.new_connection.datasource.id === "wb") {
        var _this$new_connection$8, _this$new_connection$9;
        return ((_this$new_connection$8 = this.new_connection.name) === null || _this$new_connection$8 === void 0 ? void 0 : _this$new_connection$8.length) && ((_this$new_connection$9 = this.new_connection.datasource) === null || _this$new_connection$9 === void 0 ? void 0 : _this$new_connection$9.id) && this.validApiKey;
      }
      return false;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "链接商店",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal,
    AppSelect,
    uiForm,
    Tooltip
  }
};